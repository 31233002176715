<template>
  <div>
    <header>
      <h1>订单支付</h1>
    </header>
    <main>
      <div class="box" v-if="success">
        <div class="content">
          <p>
            <span class="label">商品订单 : </span><span>{{ orderNum }}</span>
          </p>
          <p>
            <span class="label">支付金额 : </span><span class="icon">￥</span
            ><span class="money">{{ money }}</span>
          </p>
          <div class="img">
            <div class="code">
              <img :src="codeImg" alt="" />
              <div class="text">请打开手机微信，扫一扫完成支付</div>
            </div>
            <div class="phone">
              <img src="../assets/imgs/ep_sys_wx_tip.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="success" v-else>
        <div class="top">
          <h3>
            <svg
              t="1657530761669"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1658"
              width="32"
              height="32"
            >
              <path
                d="M512 1024C229.23264 1024 0 794.76736 0 512S229.23264 0 512 0s512 229.23264 512 512-229.23264 512-512 512zM283.92448 484.07552l-41.8816 55.84896 223.41632 209.4592 339.77344-386.32448-32.57344-37.24288-307.2 316.5184-181.53472-158.2592z"
                p-id="1659"
                fill="green"
              ></path></svg
            >支付成功!
          </h3>
        </div>
        <p>
          商品订单 : <span>{{ orderNum }}</span>
        </p>
        <p class="pay">
          支付金额 : <span>￥</span><span class="money">{{ money }}</span>
        </p>
        <p><span>如有疑问请与客服联系，我们将尽快为您提供服务</span></p>
        <button class="back" @click="close">返回</button>
      </div>
    </main>
  </div>
</template>

<script>
import { query } from "../http/api";
export default {
  name: "pay",
  data() {
    return {
      codeImg: "",
      orderNum: "",
      money: "",
      success:true
    };
  },
  methods: {
    close(){
        window.close();
    }
  },
  mounted() {
    var url = window.location.search;
    var paramsSearch = new URLSearchParams(url);
    this.codeImg = paramsSearch.get("img");
    this.orderNum = paramsSearch.get("orderNumber");
    this.money = paramsSearch.get("money");
    var timer = null;
    timer = setInterval(() => {
      query({ orderNo: this.orderNum }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          clearInterval(timer);
          this.$message.success({
            duration: 2000,
            message: "支付成功!",
          });
          this.success = false
        }
      });
    }, 2000);
  },
};
</script>

<style lang="scss" scoped>
.icon {
  color: #0ac265;
  font-size: 18px;
}

header {
  height: 60px;
  background-color: #fff;
}

header h1 {
  width: 1080px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 60px;
}

.box {
  width: 1080px;
  height: 578px;
  margin: 50px auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box .content {
  width: 560px;
}

.box .content p {
  color: #707070;
  font-size: 14px;
}

.box .content .label {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-left: 32px;
}

.box .content .money {
  color: #0ac265;
  font-size: 24px;
}

.box .content p:last-child {
  border-bottom: 1px solid rgb(238, 234, 234);
  padding-bottom: 20px;
}

.box .content .code {
  position: relative;
  width: 270px;
  height: 270px;
}

.box .content .code img {
  width: 100%;
  height: 100%;
}

.box .content .text {
  background-color: #00c800;
  color: #fff;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  width: 200px;
  position: absolute;
  bottom: 0;
  left: 35px;
}

.box .content .img {
  display: flex;
}

.box .content .phone {
  padding-top: 24px;
}

.success {
  width: 1080px;
  height: 578px;
  margin: 50px auto;
  background-color: #fff;
}

.success .top {
  line-height: 110px;
  background-color: #f5fef3;
  margin-bottom: 100px;
}

.success .icon {
  vertical-align: middle;
  margin-left: 70px;
  margin-right: 10px;
}

.success .top h3 {
  padding-left: 30px;
}

.success .back {
  background-color: #4580cb;
  color: #fff;
  font-size: 16px;
  width: 130px;
  height: 50px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.success .back:hover {
  background-color: #6491cb;
}

.success p {
  margin-left: 100px;
}

.success span {
  color: #999;
}

.success .pay {
  margin-top: 30px;
  margin-bottom: 40px;
}

.success button {
  margin-left: 100px;
  margin-top: 80px;
}
</style>